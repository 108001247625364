<template>
  <div>
    <v-row v-if="next_page!=='confirmar_reserva'">
      <v-col>
        <v-btn class="pl-1 pr-1 white--text" x-large color="degrade-background" @click.prevent="go_to_centros" block>Comenzar reserva sin
          cuenta
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else-if="next_page==='confirmar_reserva'">
      <v-col>
        <v-alert
            type="primary"
            icon="mdi-alert-decagram"
        >¡Atención! Para finalizar tu reserva debes iniciar sesión o regístrarte.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert
            type="secondary"
            icon="mdi-alert-decagram"
        >Si ya eres usuario de la App Elha, inicia sesión con tu email y contraseña 👇🏻
        </v-alert>
        <p class="text-center">Inicia sesión</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form ref="form" v-model="form_valid">
          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field v-model="email" :rules="email_rules" label="Email" required></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field
                  v-model="password"
                  :counter="6"
                  :rules="password_rules"
                  label="Password"
                  type="password"
                  required
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0">
              <RecoverPassword
                  ref="recover_password"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn :disabled="!form_valid" color="primary" @click.prevent="login" block>Entrar</v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <Registro
                  ref="registro"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="mt-5">
          <img style="max-width:200px" class="mx-auto d-block" src="../assets/logo-elha.png" alt="logo-elha">
        </div>
      </v-col>
    </v-row>

  </div>

</template>

<script>
import send_stats_mixin from "@/mixins/send_stats_mixin";
import get_utms from '../utils/utm_params'

export default {
  name: 'Login',
  mixins: [send_stats_mixin],
  data() {
    return {
      form_valid: true,
      email: '',
      email_rules: [
        v => !!v || 'El email es obligatorio',
        v => /.+@.+/.test(v) || 'El email debe ser válido'
      ],
      password: '',
      password_rules: [
        v => !!v || 'El password es obligatorio',
        v => (v && v.length >= 6) || 'El password debe tener al menos 6 caracteres'
      ],

      next_page: 'confirmar_reserva',
      utms: get_utms()
    }
  },

  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        let user = await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password
        })
        if (user) {
          this.$notify({
            title: 'Acción realizada correctamente',
            text: 'Login correcto'
          })
          // Contamos las citas pendientes
          let reservas_pendientes = 0
          user.reservas_usuario_portal.forEach(
              current_reserva => {
                if (current_reserva.estado.id === 2) {
                  ++reservas_pendientes
                }
              }
          )
          if (reservas_pendientes > 0) {
            const resp = await this.$alert(
                "Para poder realizar una nueva reserva, primero tienes que anular las citas pendientes.",
                "",
                'info'
            )
            await this.$router.replace({ name: 'reservas', query: this.utms })
          } else {
            setTimeout(() => {
              this.$router.push({ name: this.next_page, query: this.utms })
            }, 1500)
          }
        } else {
          this.$notify({
            title: 'Error',
            text: 'Login incorrecto',
            style: 'error'
          })
        }
      }
    },

    go_to_centros() {
      this.$router.push({ name: "centros", query: this.utms })
    }
  },

  mounted() {
    if (this.$route.query.next) {
      this.next_page = this.$route.query.next
    }

    if (this.$route.query.action === 'signup') {
      this.next_page = this.$route.query.action
      setTimeout(() => {
        this.$refs.registro.dialog = true
      }, 500)
    }

    if (this.$route.query.action === 'recover_password') {
      this.next_page = this.$route.query.action
      setTimeout(() => {
        this.$refs.recover_password.dialog = true
      }, 500)
    }
  },

  components: {
    Registro: () => import('@/components/Registro'),
    RecoverPassword: () => import('@/components/RecoverPassword')
  }
}
</script>
